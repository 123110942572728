import "./src/styles/_reset.scss"
import "@fortawesome/fontawesome-svg-core/styles.css"

export const onRouteUpdate = ({ location }) => {
  if(location.pathname ==='/') {

    //enter animation
    setTimeout(() => {
      const target = document.querySelector('#container');
      target.classList.add('loaded');
    }, 100)

    // show name
    const header = document.getElementById('header');
    const observerCopy = new IntersectionObserver ((entries) => {
      for (const e of entries) {
        // 監視対象が画面内にあるとき
        if (e.isIntersecting) {
          header.classList.remove('scrolled');
        // 監視対象が画面外にあるとき
        } else {
          header.classList.add('scrolled');
        }
      }
    });
    observerCopy.observe(document.querySelector('#copy-description'));


    // header text change color
    const options = {
      root: null,
      rootMargin: "0px 0px -94% 0px",
      threshold: 0
    };

    const elm = document.querySelector("#contact");
    const callback = function(entries, observer) {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          header.classList.add('contact-is-current');
        } else {
          header.classList.remove('contact-is-current');
        }
      });
    }

    const io = new IntersectionObserver(callback, options);
    io.observe(elm);


    // add animation
    const op = {
      root: null,
      rootMargin: "100% 0% 100px 0%",
      threshold: 0.5
    }

    const obs = new IntersectionObserver(showIntersect, op)
    const imgs = document.querySelectorAll('.animation')
    imgs.forEach(img => obs.observe(img))

    function showIntersect(changes) {
      changes.forEach(change => {
        if (change.isIntersecting) {
          change.target.classList.add('is-show');
        } else {
          change.target.classList.remove('is-show');
        }
      })
    }

  }
}